import React, { PropsWithChildren, ReactNode, useCallback, useMemo, useState } from 'react';
import { SnackBarContext, SnackbarOptions } from '@ev/eva-container-api';
import { AlertColor, Box, Link, SvgIcon } from '@mui/material';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';

import CrossIcon from '~/icons/cross-icon.svg?react';

interface Notification {
  message: ReactNode;
  id: string;
  type: AlertColor;
  options?: SnackbarOptions;
}

let nextId = 1;

export function NotificationProvider({ children }: PropsWithChildren) {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const openSnackBar = useCallback((message: string | ReactNode, type: AlertColor, options?: SnackbarOptions): string => {
    const notification = {
      message,
      // eslint-disable-next-line no-plusplus
      id: `${++nextId}`,
      type,
      options,
    };

    setNotifications((currentNotifications) => [...currentNotifications, notification]);

    return notification.id;
  }, []);

  const closeSnackBar = useCallback((id?: string) => {
    if (id) {
      setNotifications((currentNotifications) => currentNotifications.filter((n) => n.id !== id));
    }
  }, []);

  const context = useMemo(() => ({ openSnackBar, closeSnackBar }), [closeSnackBar, openSnackBar]);

  const notification = notifications.length ? notifications[notifications.length - 1] : null;

  // Banner notifications are not supported they are shown as sticky instead
  const dontAutoHide = notification?.options?.isSticky || notification?.options?.asBanner;

  const action = notification?.options?.action;

  return (
    <SnackBarContext.Provider value={context}>
      {notification && (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open
          autoHideDuration={dontAutoHide ? undefined : 3000}
          onClose={() => closeSnackBar(notification.id)}
          ClickAwayListenerProps={{ onClickAway: () => null }}
        >
          <Alert
            severity={notification.type}
            action={
              <>
                {action && (
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <Link
                    sx={{ color: 'neutral.dark' }}
                    href={'link' in action ? action.link : undefined}
                    onClick={'callback' in action ? action.callback : undefined}
                  >
                    {action.text}
                  </Link>
                )}

                {dontAutoHide && (
                  <IconButton size="small" aria-label="close" color="inherit" onClick={() => closeSnackBar(notification.id)}>
                    <SvgIcon component={CrossIcon} />
                  </IconButton>
                )}
              </>
            }
          >
            <Box sx={{ minWidth: 150, maxWidth: 260 }}>{notification.message}</Box>
          </Alert>
        </Snackbar>
      )}
      {children}
    </SnackBarContext.Provider>
  );
}
